import { useAuthStore, useMainStore } from "@/store";
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API,
    withCredentials: true,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=utf-8;",
        Accept: "application/json",
    },
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const auth = useAuthStore();
        const main = useMainStore();
        // Do something before request is sent
        // console.log(auth.getToken);
        if (auth.getToken) {
            config.headers["Authorization"] = "Bearer " + auth.getToken;
        }
        main.reset();

        return config;
    },
    (error) => {
        // Do something with request error
        const main = useMainStore();
        main.setLoading(false);
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    ({ data }) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // const auth = useAuthStore();
        // const main = useMainStore();
        const main = useMainStore();
        if (
            data &&
            data.code &&
            data.code !== 200 &&
            !data.message.includes("spam")
        ) {
            main.addMessage(data);
        }
        if (!main.startSearch) {
            main.setLoading(false);
        }
        return data;
    },
    ({ response }) => {
        const main = useMainStore();
        if (
            response &&
            response.data &&
            response.data.message === "Unauthenticated."
        ) {
            const auth = useAuthStore();
            auth.resetAuth();
            main.setModal("auth", response.data.message);
        }

        if (
            response &&
            !response.data.success &&
            response.data.message.includes("spam")
        ) {
            main.setModal("captcha", 1);
        }

        if (
            response &&
            response.data &&
            !response.data.message.includes("spam")
        ) {
            main.addMessage(response.data);
            main.setLoading(true);
        }

        if (!response.data.success) {
            main.setLoading(false);
            main.setSearch(false);
        }
        return Promise.reject(response.data);
    }
);
export default axiosInstance;
